import React from 'react';
import {Box, Flex, Image} from 'rebass/styled-components';
import {StaticQuery, graphql} from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Fade from 'react-reveal/Fade';

import Section from '../../components/Section';
import markdownRenderer from '../../components/MarkdownRenderer';

import Background from './background';

const About = () => (
  <Section.Container id="about" Background={Background}>
    <Section.Header name="About" />
    <StaticQuery
      query={graphql`
        query AboutMeQuery {
          contentfulHome {
            aboutMe {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            profile {
              title
              image: resize(width: 450, quality: 100) {
                src
              }
            }
          }
        }
      `}
      render={(data) => {
        const {aboutMe, profile} = data.contentfulHome;
        return (
          <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
            <Box width={[1, 1, 4 / 6]} px={[1, 2, 4]}>
              <Fade bottom>
                <ReactMarkdown
                  source={aboutMe.childMarkdownRemark.rawMarkdownBody}
                  renderers={markdownRenderer}
                />
              </Fade>
            </Box>
            <Box
              width={[1, 1, 2 / 6]}
              style={{maxWidth: '250px', margin: 'auto'}}>
              <Fade right>
                <Image
                  src={profile.image.src}
                  alt={profile.title}
                  mt={[4, 4, 0]}
                  ml={[0, 0, 1]}
                />
              </Fade>
            </Box>
          </Flex>
        );
      }}
    />
  </Section.Container>
);

export default About;
