import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Fade from 'react-reveal/Fade';

import Section from '../../components/Section';
import markdownRenderer from '../../components/MarkdownRenderer';

import Background from './background';

const Contact = () => (
  <Section.Container id="contact" Background={Background}>
    <Section.Header name="Contact" />
    <StaticQuery
      query={graphql`
        query ContactQuery {
          contentfulHome {
            contact {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
        }
      `}
      render={(data) => {
        const {contact} = data.contentfulHome;
        return (
          <Fade bottom>
            <ReactMarkdown
              source={contact.childMarkdownRemark.rawMarkdownBody}
              renderers={markdownRenderer}
            />
          </Fade>
        );
      }}
    />
  </Section.Container>
);

export default Contact;
