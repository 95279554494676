import React, {Fragment, useEffect} from 'react';
import {StaticQuery, graphql} from 'gatsby';
import {Heading, Text} from 'rebass/styled-components';
import TextLoop from 'react-text-loop';
import {SectionLink} from 'react-scroll-section';

import Section from '../../components/Section';
import MouseIcon from '../../components/MouseIcon';

import Background from './background';
import {ReactNativeAnimation} from './styled';

const LandingPage = () => {
  useEffect(() => {
    // only start the animation if the document is visible on load
    const steps = ['full', 'mobile', 'desktop', 'laptop', 'mobile2', 'full2'];
    const intervals = [1250, 1500, 1500, 1500, 1500, 1250];

    let i = 0;
    const logo = document.querySelector('.LogoAnimation');
    const timeouts = [];

    const animateStep = () => {
      const prev = steps[i];
      logo.classList.remove(prev);
      i = (i + 1) % steps.length;
      const current = steps[i];
      const timeout = intervals[i];
      logo.classList.add(current);

      timeouts.push(setTimeout(animateStep, timeout));
    };

    if (!document.hidden) {
      timeouts.push(
        setTimeout(() => {
          logo.classList.remove('init');
          animateStep();
        }, 2000),
      );
    }

    return () => {
      if (document.hidden) {
        timeouts.forEach((timeout) => {
          clearTimeout(timeout);
        });
        timeouts.length = 0;
      } else {
        animateStep();
      }
    };
  }, []);

  return (
    <Section.Container id="home" Background={Background}>
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            contentfulHome {
              name
              description
              roles
            }
            site {
              siteMetadata {
                deterministicBehaviour
              }
            }
          }
        `}
        render={({contentfulHome, site}) => {
          const {name, description, roles} = contentfulHome;
          const {deterministicBehaviour} = site.siteMetadata;

          return (
            <Fragment>
              <Heading
                textAlign="center"
                as="h1"
                color="primary"
                fontSize={[6, 7]}
                mb={[1, 2, 3]}>
                {`${name}`}
              </Heading>
              <Heading
                textAlign="center"
                as="h2"
                color="primary"
                fontSize={[4, 5]}
                mb={[0, 1, 2]}>
                {`${description}`}
              </Heading>
              <ReactNativeAnimation />
              <Heading
                as="h3"
                color="primaryLight"
                mt={[1, 2, 3]}
                fontSize={[4, 5]}
                textAlign="center"
                style={{marginRight: 'auto', marginLeft: 'auto'}}>
                <TextLoop interval={3000}>
                  {roles
                    .sort(() => deterministicBehaviour || Math.random() - 0.5)
                    .map((text) => (
                      <Text width={[300, 500]} key={text}>
                        {text}
                      </Text>
                    ))}
                </TextLoop>
              </Heading>
              <SectionLink section="about">
                {({onClick}) => <MouseIcon onClick={onClick} />}
              </SectionLink>
            </Fragment>
          );
        }}
      />
    </Section.Container>
  );
};

export default LandingPage;
