import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import Fade from 'react-reveal/Fade';

import Section from '../../components/Section';
import {CardContainer} from '../../components';
import {Project} from '../../components';

import Background from './background';

const Projects = () => (
  <Section.Container id="projects" Background={Background}>
    <Section.Header name="Projects" />
    <StaticQuery
      query={graphql`
        query ProjectsQuery {
          contentfulHome {
            projects {
              id
              name
              publishedDate(formatString: "YYYY")
              overview
              appStoreUrl
              playStoreUrl
              websiteUrl
              slug
              logo {
                title
                image: resize(width: 200, quality: 100) {
                  src
                }
              }
            }
          }
        }
      `}
      render={({contentfulHome}) => (
        <CardContainer minWidth="350px">
          {contentfulHome.projects.map((project, i) => (
            <Fade bottom delay={i * 200} key={project.id}>
              <Project {...project} />
            </Fade>
          ))}
        </CardContainer>
      )}
    />
  </Section.Container>
);

export default Projects;
